import { Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ValidationDefaultErrors } from '../../constants/validation-errors';
import {camelToTitleCase} from '../../utils/index';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        return this.validateFormControlField(control);
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
      else if (control instanceof FormArray) {
        this.validateFormArrayField(control);
      }
    });
  }

  /**
   * 
   * @param control FormControl instance
   * mark control as touched
   */
  validateFormControlField(control: FormControl) {
    control.markAsTouched({ onlySelf: true });
  }

  /**
   * 
   * @param formArray FormArray instance
   * 
   * validate all form fields in formArray
   * - if control is FormControl, mark it as touched
   * - if control is FormGroup, validate all form fields in it using validateAllFormFields
   * - if control is FormArray, validate all form fields in it using validateFormArrayField recursively
   */
  validateFormArrayField(formArray: FormArray) {
    formArray.controls.forEach(control => {
      if (control instanceof FormControl) {
        this.validateFormControlField(control);
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      } else if (control instanceof FormArray) {
        this.validateFormArrayField(control);
      }
    });
  }

  validateTemplateDrivenForm(form) {
    for (const control in form.controls) {
      if (form.controls[control]) {
        form.controls[control].markAsTouched();
      }
    }
  }

  /**
   * @param formControl
   * @param forceCheck
   * returns control's err messages
   */
  validateFormControl(formControl: AbstractControl, forceCheck, controlName?: string): string[] {
    const errMsgs = [];
    if (forceCheck || (formControl.dirty || formControl.touched)) {
      if (formControl.invalid && formControl.errors) {
        for (const key of Object.keys(formControl.errors)) {
          if (ValidationDefaultErrors[key]) {
            errMsgs.push(ValidationDefaultErrors[key].call(this, formControl.errors[key], camelToTitleCase(controlName)));
          }
        }
      }
    }
    return errMsgs;
  }
  /**
   * @param form
   * @param forceCheckAll incase of form is submitted
   * returns form's err messages
   */
  validateReactiveForm(form: FormGroup | FormArray, forceCheckAll = false, includeControlNameInMsg = false) {
    const formErrMsgs = {};
    try {
      for (const control of Object.keys(form.controls)) {
        const formControl = form.controls[control];
        if (formControl instanceof FormGroup || formControl instanceof FormArray) {
          formErrMsgs[control] = this.validateReactiveForm(formControl, forceCheckAll); // recursive call - for nested forms or formArray 
        } else {
          formErrMsgs[control] = this.validateFormControl(formControl, forceCheckAll, includeControlNameInMsg ? control : null);
        }
      }
    } catch (e) {
    } finally {
      return formErrMsgs;
    }
  }

removeSpaces(control: AbstractControl) {
  if (control && control.value) {
    let removedSpaces = control.value.split(' ').join('');
    control.value !== removedSpaces && control.setValue(removedSpaces);
  }
  return null;
}


}