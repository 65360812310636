import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { OmsService } from '../services/oms.service';

@Directive({
  selector: '[appCompanyDisplayName]'
})
export class CompanyDisplayNameDirective {
  @Input() changeTextAfterViewInit = false;
  constructor(private omsService: OmsService, private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    this.modifyValues();
  }

  ngAfterViewInit() {
    if (this.changeTextAfterViewInit) this.modifyValues();
  }

  modifyValues() {
    const isChannelPartner = this.omsService.getIsChannelPartner();
    const isInternationalCompany = this.omsService.getIsInternationalCompany();
    const textContent = this.el.nativeElement.textContent;
    const placeHolder = this.el.nativeElement.getAttribute('placeholder');
    const ngReflectPlaceHolder = this.el.nativeElement.getAttribute('ng-reflect-placeholder');

    if (!isChannelPartner && !isInternationalCompany) return;

    /**
     * For changing text of tags
    */
    if (textContent) {
      const modifiedText = textContent.replace(/zetwerk/gi, 'Company');

      this.renderer.setProperty(this.el.nativeElement, 'textContent', modifiedText);
    }

    /**
     * For changing placeholders
    */
    if (placeHolder) {
      const modifiedPlaceHolder = placeHolder.replace(/zetwerk/gi, 'Company');
      this.renderer.setAttribute(this.el.nativeElement, 'placeholder', modifiedPlaceHolder);

      /**
       * For ng-select, the placeholder is actually the text of a child with class ng-placeholder
      */
      const child = this.el.nativeElement.querySelector('.ng-placeholder');
      if (child) {
        this.renderer.setProperty(child, 'textContent', modifiedPlaceHolder);
      }
    }

    if (ngReflectPlaceHolder) {
      const modifiedNgReflectPlaceHolder = ngReflectPlaceHolder.replace(/zetwerk/gi, 'Company');
      this.renderer.setAttribute(this.el.nativeElement, 'ng-reflect-placeholder', modifiedNgReflectPlaceHolder);
    }
  }
}
