import { Injectable } from '@angular/core';
import { OmsService } from './oms.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

const USER_URL = environment.userEndPoint;

@Injectable({
  providedIn: 'root'
})
export class TenantService {
  selectedTenantId: string;
  selectedTenant: any;
  constructor(
    private http: HttpClient,
    private omsService: OmsService
  ) { }

  getTenantId() {
    return this.selectedTenantId;
  }

  setTenantId() {
    const companies = this.omsService.getUserCompany() || [];
    const selectedCompanySlug = this.omsService.getSelectedCompany();

    const selectedCompany = companies.find((company) => company.slug === selectedCompanySlug);

    this.selectedTenantId = selectedCompany?.companyDetails?.tenantId;
    localStorage.setItem('tenantId', this.selectedTenantId);
  }


  /**
   * fetches tenant details from selected tenant id
  */
  public fetchTenant(tenantId?) { 
    return this.http.get(
      USER_URL + `tenant/${tenantId || this.selectedTenantId}`
    );
  }

  setTenantDetails() {
    this.setTenantId();
    this.fetchTenant().subscribe((response) => {
      if (response['success']) {
        this.selectedTenant = response['data'];
      }
    }, () => {});
  }


  getTenantDetails() {
    return this.selectedTenant;
  }

  getTenantConfig() {
    return this.selectedTenant?.config;
  }

  getTenantProfile() {
    return this.selectedTenant?.tenantProfileDetails;
  }
}
