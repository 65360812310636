import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { TenantService } from '../services/tenant.service';
import { getValueForTenant } from '../utils';
import { TENANT_PARAMETER_PATH_MAP } from '../constants/tenant';

@Directive({
  selector: '[appRegulatoryDocumentName]'
})
export class RegulatoryDocumentNameDirective {
  @Input() changeTextAfterViewInit = false;
  constructor(
    private tenantService: TenantService,
    private el: ElementRef,
    private renderer: Renderer2
  ) {}

  ngOnInit() {
    this.modifyValues();
  }

  ngAfterViewInit() {
    if (this.changeTextAfterViewInit) this.modifyValues();
  }

  modifyValues() {
    const textContent = this.el.nativeElement.textContent;
    const placeHolder = this.el.nativeElement.getAttribute('placeholder');
    const ngReflectPlaceHolder = this.el.nativeElement.getAttribute('ng-reflect-placeholder');


    const sourceRegex = new RegExp('gst', 'gi');
    const targetValue = getValueForTenant(
      TENANT_PARAMETER_PATH_MAP.TAX_DOCUMENT_NAME,
      this.tenantService.getTenantDetails()
    ) || 'Tax Region';

    /**
     * For changing text of tags
    */
    if (textContent) {
      const modifiedText = textContent.replace(sourceRegex, targetValue);

      this.renderer.setProperty(this.el.nativeElement, 'textContent', modifiedText);
    }

    /**
     * For changing placeholders
    */
    if (placeHolder) {
      const modifiedPlaceHolder = placeHolder.replace(sourceRegex, targetValue);
      this.renderer.setAttribute(this.el.nativeElement, 'placeholder', modifiedPlaceHolder);

      /**
       * For ng-select, the placeholder is actually the text of a child with class ng-placeholder
      */
      const child = this.el.nativeElement.querySelector('.ng-placeholder');
      if (child) {
        this.renderer.setProperty(child, 'textContent', modifiedPlaceHolder);
      }
    }

    if (ngReflectPlaceHolder) {
      const modifiedNgReflectPlaceHolder = ngReflectPlaceHolder.replace(sourceRegex, targetValue);
      this.renderer.setAttribute(this.el.nativeElement, 'ng-reflect-placeholder', modifiedNgReflectPlaceHolder);
    }
  }
}
