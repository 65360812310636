import {
  Env,
  IZetAppCoreModule,
  ILoginConfig,
  LoginType,
} from "@zetwerk/zetui";
import { environment } from "../../environments/environment";

export const loginConfig: ILoginConfig = {
  title: { text: "Channel Partner", subText: "Sign In to" },
  loginProviders: [
    {
      id: LoginType.GOOGLE,
      clientId: environment.googleClientId,
      cookieBasedAuth: true,
      label: "Order Management System",
    },
    { id: LoginType.EMAIL_OTP },
    {
      id: LoginType.MICROSOFT,
      clientId: environment.microsoftClientId,
      label: "Order Management System",
      cookieBasedAuth: true,
      redirect_uri: environment.redirect_uri,
    },
    {
      id: LoginType.GOOGLE_MICROSOFT,
      googleClientId: environment.googleClientId,
      microsoftClientId: environment.microsoftClientId,
      label: "Order Management System",
      googleCookieBasedAuth: true,
      microsoftCookieBasedAuth: true,
      redirect_uri: environment.redirect_uri,
    },
  ],
};
export const zetAppModuleConfig: IZetAppCoreModule = {
  appName: "oms-ui",
  env: Env[environment.env],
  featuresEnabled: {
    login: true,
    loader: false,
    auth: true,
    deployment: true,
    http: false,
  },
  login: {
    config: loginConfig,
  },
  auth: {
    config: { authUrl: environment.authApiEndPoint },
    // authInterceptor: true
  },
};
