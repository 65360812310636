import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';

const API_URL = environment.apiEndPoint;
const CMS_URL = environment.cmsApiEndPoint;
const ZISO_URL = environment.zisoApiEndPoint;

@Injectable({
  providedIn: 'root'
})
export class CustomersService {

  constructor(private http: HttpClient) { }

  private handleSuccess(response: HttpResponse<any>) {
    if (response.status === 200) {
      return { 'body': response.body, 'headers': response.headers };
    }
    return JSON.parse(response.body);
  }

  public createCustomer(data) {
    return this.http.post(CMS_URL + 'customers', data);
  }

  public getCustomers(urlParams) {
    urlParams.recordsPerPage = 0;
    return this.http.get(CMS_URL + 'customers', {
      params: {
        ...urlParams
      }
    });
  }

  public getIndustries() {
    return this.http.get(API_URL + 'industries');
  }

  public updateCustomer(customerId, data) {
    return this.http.put(CMS_URL + 'customers/' + customerId, data);
  }

  public deleteCustomer(customerId) {
    return this.http.delete(CMS_URL + 'customers/' + customerId);
  }

  public getContractByCustomerId(customerId) {
    return this.http.get(API_URL + `customers/${customerId}/payables`);
  }

  /**
   * Get contracts for customer group
   * @param customerGroupId
   * @returns observable
   */
  public getContractByCustomerGroupId(customerGroupId: any) {
    return this.http.get(API_URL + `customer-groups/${customerGroupId}/payables`);
  }

  /**
   * Get Payables for a contract
   * @param contractId
   * @returns observable
   */
  public getPayablesForContract(contractId: any) {
    return this.http.get(API_URL + `customer-groups/${contractId}/contract-payables`);
  }

  public getCustomerById(customerId) {
    return this.http.get(CMS_URL + 'customers/' + customerId, {});
  }

  public saveCustomerAddress(customerId, type, data) {
    return this.http.post(CMS_URL + `customers/${customerId}/add-address?addressType=${type}`, data);
  }

/**
 *
 * @param customerId
 * @param bankAccData
 */
  public addCustomerBankAccDetails(customerId, bankAccData) {
    return this.http.post(CMS_URL + `customers/${customerId}/add-bank-account`, bankAccData);
  }

/**
 *
 * @param customerId
 * @param editedBankAccData
 */
  public updateCustomerBankAccDetails(customerId, editedBankAccData, bankAccId) {
    return this.http.put(CMS_URL + `customers/${customerId}/update-bank-account/${bankAccId}`, editedBankAccData);
  }

  /**
   * Returns Customer/Customer Group URL
   * @param customerId
   * @param customerGroupId
   * @returns Customer URL
   */
  getCustomerURL(customerId: any, customerGroupId: any) {
    return `${API_URL}${customerGroupId ? "customer-groups" : "customers"}/${
      customerGroupId ? customerGroupId : customerId
    }`;
  }

/**
 *
 * @param customerId
 */
  public getCustomerLedger(customerId, params, customerGroupId: any = null) {
    const url = `${this.getCustomerURL(customerId, customerGroupId)}/ledger-list`
    return this.http.get(url, {params});
  }

  getCustomerLedgerSummary(customerId, params, customerGroupId: any = null) {
    return this.http.get(`${this.getCustomerURL(customerId, customerGroupId)}/ledger-summary`, { params });
  }

  getCustomerOpeningBalance(customerId, params, customerGroupId: any = null) {
    return this.http.get(`${this.getCustomerURL(customerId, customerGroupId)}/ledger-opening-balance`, { params });
  }

  validateCustomerOnEdit(customerId) {
    return this.http.get(`${API_URL}customers/${customerId}/validate-customer-edit`);
  }

  /**
   * Get Customers List from ZISO
   * @param params
   * @returns CustomerList[]
   */
  getCustomerList(params){
    return this.http.get(`${ZISO_URL}customers`,{params})
  }

  /**
   * Get Customer Group List
   * @params params
   * @returns customerGroupList[]
   */
   getCustomerGroupList(params) {
    return this.http.get(`${CMS_URL}customergroups`, { params });
  }

  /**
   * get the dss customer group by customerGroupId
   * @param params 
   */
  getDssCustomerGroup(customerGroupId) {
    return this.http.get(`${CMS_URL}dsscustomergroups/${customerGroupId}`);
  }

  getRefMasterData({ tenantId, masterType }) {
    return this.http.get(
      `${CMS_URL}refmaster/${tenantId}?masterType=${masterType}`
    );
  }

  getCustomerKycFields(params) {
    return this.http.get(
      `${CMS_URL}refmaster/get-field-configurations`,
      { params }
    );
  }
}
