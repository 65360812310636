export const TENANT_PARAMETER_PATH_MAP = {
    ADDRESS_VALIDATION_SKIP_SO: 'config.featureConfiguration.address.skipValidationForSOCreation',
    TAX_DOCUMENT_NAME: 'config.featureConfiguration.regulatory.documentName',
    IS_NON_GST_TAX: 'config.featureConfiguration.regulatory.isNonGSTTax',
    IS_I18N_ADDRESS: 'config.featureConfiguration.regulatory.isNonGSTTax',
    PREVENT_AR_AP_CN_DN_FLOW: 'config.featureConfiguration.preventArApCnDnFlow',
    PREVENT_AR_AP_OLD_FLOW: 'config.featureConfiguration.preventArApOldFlow',
    IS_IFSC_NOT_APPLICABLE: 'config.featureConfiguration.bank.isIFSCNotApplicable',
    HIDE_BILL_FIELDS: 'config.featureConfiguration.hideBillFieldsForForeignEntity',
    IS_TAX_RATES_EDITABLE_AT_BILL_SUBMIT: 'config.featureConfiguration.isTaxRatesEditableAtBillSubmit'
}