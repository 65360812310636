import { OptionModel } from 'src/app/models/tab-model';
import { IColumnDef,IFilterOptions } from "@zetwerk/zet-list";

export const EWAY_BILL_STATUS = {
  GENERATED: 'GENERATED',
  CANCELLED: 'CANCELLED'
};


export enum AddressTypes {
  BILL_FROM = 'Bill From',
  BILL_TO = 'Bill To',
  DISPATCH_FROM = 'Dispatch From',
  SHIP_TO = 'Ship To'
}

export const SampleSupplyTypes = {
  outward: <OptionModel>{
    label: 'Outward',
    value: 'OUTWARD'
  },
  inward: <OptionModel>{
    label: 'Inward',
    value: 'INWARD'
  }
};

export const SampleSubTypes = {
  supply: <OptionModel>{
    label: 'Supply',
    value: 'Supply'
  },
  job_work: <OptionModel>{
    label: 'Job Work',
    value: 'Job Work'
  },
  export: <OptionModel>{
    label: 'Export',
    value: 'Export'
  },
  skd_ckd_lots: <OptionModel>{
    label: 'SKD/CKD/Lots',
    value: 'SKD/CKD'
  },
  recepient_not_known: <OptionModel>{
    label: 'Recipient Not Known',
    value: 'Recipient not known'
  },
  for_own_use: <OptionModel>{
    label: 'For Own Use',
    value: 'For Own Use'
  },
  exhibition: <OptionModel>{
    label: 'Exhibition',
    value: 'Exhibition'
  },
  fairs: <OptionModel>{
    label: 'Fairs',
    value: 'Fairs'
  },
  line_sales: <OptionModel>{
    label: 'Line Sales',
    value: 'Line Sales'
  },
  sales_return: <OptionModel>{
    label: 'Sales Return',
    value: 'Sales Return'
  },
  job_work_returns: <OptionModel>{
    label: 'Job Work Returns',
    value: 'Job Work Returns'
  },
  import: <OptionModel>{
    label: 'Import',
    value: 'Import'
  },
  others: <OptionModel>{
    label: 'Others',
    value: 'Others'
  }
};

export const SampleTransactionTypes: OptionModel[] = [
  {
    label: 'Regular',
    value: 'REGULAR'
  }, {
    label: 'Bill to - Ship to',
    value: 'Bill to-ship to'
  }, {
    label: 'Bill from - Disp From',
    value: 'Bill from-dispatch from'
  }, {
    label: 'Combination',
    value: 'COMBINATION'
  }
];

export const DOCTYPES = {
  TAX_INVOICE : new OptionModel('Tax Invoice', 'Tax Invoice'),
  BILL_OF_SUPPLY : new OptionModel('Bill of Supply', 'Bill of Supply'),
  DELIVERY_CHALLAN : new OptionModel('Delivery Challan', 'Delivery Challan'),
  DEBIT_NOTE : new OptionModel('Debit Note', 'Debit Note'),
  CREDIT_NOTE : new OptionModel('Credit Note', 'Credit Note'),
  BILL_OF_ENTRY : new OptionModel('Bill of Entry', 'Bill of Entry'),
  OTHERS : new OptionModel('Others', 'Others')
};

export const DOC_SUPPLY_SUB_MAP = [
  {
    supplyType: SampleSupplyTypes.outward,
    supplySubType: SampleSubTypes.supply,
    docTypes: [DOCTYPES.TAX_INVOICE, DOCTYPES.BILL_OF_SUPPLY]
  }, {
    supplyType: SampleSupplyTypes.outward,
    supplySubType: SampleSubTypes.export,
    docTypes: [DOCTYPES.TAX_INVOICE, DOCTYPES.BILL_OF_SUPPLY]
  }, {
    supplyType: SampleSupplyTypes.outward,
    supplySubType: SampleSubTypes.job_work,
    docTypes: [DOCTYPES.DELIVERY_CHALLAN]
  }, {
    supplyType: SampleSupplyTypes.outward,
    supplySubType: SampleSubTypes.skd_ckd_lots,
    docTypes: [DOCTYPES.TAX_INVOICE, DOCTYPES.BILL_OF_SUPPLY, DOCTYPES.DELIVERY_CHALLAN]
  }, {
    supplyType: SampleSupplyTypes.outward,
    supplySubType: SampleSubTypes.recepient_not_known,
    docTypes: [DOCTYPES.DELIVERY_CHALLAN, DOCTYPES.OTHERS]
  }, {
    supplyType: SampleSupplyTypes.outward,
    supplySubType: SampleSubTypes.for_own_use,
    docTypes: [DOCTYPES.DELIVERY_CHALLAN]
  }, {
    supplyType: SampleSupplyTypes.outward,
    supplySubType: SampleSubTypes.exhibition,
    docTypes: [DOCTYPES.DELIVERY_CHALLAN]
  }, {
    supplyType: SampleSupplyTypes.outward,
    supplySubType: SampleSubTypes.fairs,
    docTypes: [DOCTYPES.DELIVERY_CHALLAN]
  }, {
    supplyType: SampleSupplyTypes.outward,
    supplySubType: SampleSubTypes.line_sales,
    docTypes: [DOCTYPES.DELIVERY_CHALLAN]
  }, {
    supplyType: SampleSupplyTypes.outward,
    supplySubType: SampleSubTypes.others,
    docTypes: [DOCTYPES.OTHERS, DOCTYPES.DELIVERY_CHALLAN]
  },

  {
    supplyType: SampleSupplyTypes.inward,
    supplySubType: SampleSubTypes.supply,
    docTypes: [DOCTYPES.TAX_INVOICE, DOCTYPES.BILL_OF_SUPPLY]
  }, {
    supplyType: SampleSupplyTypes.inward,
    supplySubType: SampleSubTypes.import,
    docTypes: [DOCTYPES.BILL_OF_ENTRY]
  }, {
    supplyType: SampleSupplyTypes.inward,
    supplySubType: SampleSubTypes.skd_ckd_lots,
    docTypes: [DOCTYPES.TAX_INVOICE, DOCTYPES.BILL_OF_SUPPLY, DOCTYPES.BILL_OF_ENTRY, DOCTYPES.DELIVERY_CHALLAN]
  }, {
    supplyType: SampleSupplyTypes.inward,
    supplySubType: SampleSubTypes.job_work_returns,
    docTypes: [DOCTYPES.DELIVERY_CHALLAN]
  }, {
    supplyType: SampleSupplyTypes.inward,
    supplySubType: SampleSubTypes.sales_return,
    docTypes: [DOCTYPES.DELIVERY_CHALLAN]
  }, {
    supplyType: SampleSupplyTypes.inward,
    supplySubType: SampleSubTypes.exhibition,
    docTypes: [DOCTYPES.DELIVERY_CHALLAN]
  }, {
    supplyType: SampleSupplyTypes.inward,
    supplySubType: SampleSubTypes.fairs,
    docTypes: [DOCTYPES.DELIVERY_CHALLAN]
  }, {
    supplyType: SampleSupplyTypes.inward,
    supplySubType: SampleSubTypes.for_own_use,
    docTypes: [DOCTYPES.DELIVERY_CHALLAN]
  }, {
    supplyType: SampleSupplyTypes.inward,
    supplySubType: SampleSubTypes.others,
    docTypes: [DOCTYPES.OTHERS, DOCTYPES.DELIVERY_CHALLAN]
  }
];


// Transporataion Constants
export const TRANSPORTATION_MODES = {
  RAIL: <OptionModel>{
    label: 'Rail',
    value: 'RAIL'
  }, ROAD: <OptionModel>{
    label: 'Road',
    value: 'ROAD'
  }, AIR: <OptionModel>{
    label: 'Air',
    value: 'AIR'
  }, SHIP: <OptionModel>{
    label: 'Ship',
    value: 'SHIP'
  }
};

export const VEHICLE_TYPES: OptionModel[] = [{
  label: 'Regular' ,
  value: 'Regular'
}, {
  label: 'Over Dimensional Cargo',
  value: 'Over Dimensional Cargo'
}];


export enum  EWAYBILL_DATA_SECTION  {
  transactionDetails = 'transactionDetails',
  billFromDetails = 'billFromDetails',
  dispatchFromDetails = 'dispatchFromDetails',
  billToDetails = 'billToDetails',
  shipToDetails = 'shipToDetails',
  items = 'items',
  transportationDetails = 'transportationDetails',
  dispatchToDetails = 'dispatchToDetails',
}

export const SampleTransactionTypesForDC: OptionModel[] = [
  {
    label: 'Regular',
    value: 'REGULAR'
  }, {
    label: 'Bill to - Ship to',
    value: 'Bill to-ship to'
  }, {
    label: 'Bill from - Disp From',
    value: 'Bill from-dispatch from'
  }, {
    label: 'Combination',
    value: 'COMBINATION'
  }
];

export enum TRANSACTION_TYPE_VALUES_FOR_DC {
  REGULAR = 'REGULAR',
  BILL_TO_SHIP_TO = 'Bill to-ship to',
  BILL_FROM_DISPATCH_FROM = 'Bill from-dispatch from',
  COMBINATION = 'COMBINATION'
}

// E-way list filter options
export const E_WAY_FILTER_OPTIONS: Array<IFilterOptions> = [
  {
    id: 'eWayBillNumber',
    title: 'E-Way Bill Number',
    type: 'multiselect',
    bindLabel: 'name',
    bindValue: '_id',
    filterModelType: 'serverSide'
  },
  {
    id: 'documentNumber',
    title: 'Reference No.',
    type: 'multiselect',
    bindLabel: 'name',
    bindValue: '_id',
    filterModelType: 'serverSide'
  },
  {
    id: 'documentType',
    title: 'Document Type',
    type: 'multiselect',
    bindLabel: 'name',
    bindValue: '_id',
  },
  {
    id: 'billToDetailsName',
    title: 'Bill To',
    type: 'multiselect',
    bindLabel: 'name',
    bindValue: '_id',
    filterModelType: 'serverSide'
  },
];

// E-way bill list tabs info
export const LIST_TABS = [
    {
      name:"GENERATED",
      title: 'Generated E-Way Bill',
      status: [EWAY_BILL_STATUS.GENERATED],
      countKey: 'totalGeneratedEWayBillCount',
      active: false
    },
    {
      name:"CANCELLED",
      title: 'Cancelled E-Way Bill',
      status: [EWAY_BILL_STATUS.CANCELLED],
      countKey: 'totalCancelledEWayBillCount',
    }
];

// E-way bill list column definitions
export const E_WAY_COLUMN_DEFINITIONS: Array<IColumnDef> = [
  {
    field: 'eWayBillNumber',
    headerName: 'E-Way Bill No.',
    cellClass: 'cell-center',
    cellRenderer: 'commonCellRenderer',
    width: 200,
    valueGetter: (data) => {
      return data?.eWayBillNumber || '';
    }
  },
  {
    field: 'documentType',
    headerName: 'Document Type',
    cellClass: 'cell-center',
    cellRenderer: 'commonCellRenderer',
    width: 100,
    valueGetter: (data) => {
       return data?.documentType
      }
  },
  {
    field: 'documentNumber',
    headerName: 'Reference No.',
    cellClass: 'cell-center',
    cellRenderer: 'commonCellRenderer',
    width: 120,
    valueGetter: (data) => {
      return data?.documentNumber
     }
  },
  {
    field: 'eWayBillDueDate',
    headerName: 'Valid Till',
    cellClass: 'cell-center',
    cellRenderer: 'dateCellRenderer',
    width: 100,
    valueGetter: (data) => {
      return data?.eWayBillDueDate
    }
  },
  {
    field: 'billToDetails.name',
    headerName: 'Bill To',
    cellClass: 'cell-center',
    cellRenderer: 'commonCellRenderer',
    width: 180,
    valueGetter: (data) => {
      return data?.billToDetails?.name
     }
  },
  {
    field: 'transportationDetails.transportationMode',
    headerName: 'Mode of Transportation',
    cellClass: 'cell-center',
    cellRenderer: 'commonCellRenderer',
    width: 120,
    valueGetter: (data) => {
      return data?.transportationDetails?.transportationMode
     }
  },
];

export const GST_FOR_RETAIL_CUSTOMERS_OR_URV = 'URP';
// Transaction Types Constants

export const TRANSACTION_TYPES = {
  REGULAR: <OptionModel>{
    label: 'Regular',
    value: 'REGULAR'
  }, BILL_TO: <OptionModel>{
    label: 'Bill To',
    value: 'BILLTO'
  }, SHIP_TO: <OptionModel>{
    label: 'Ship To',
    value: 'SHIPTO'
  }, COMBINATION: <OptionModel>{
    label: 'Combination',
    value: 'COMBINATION'
  }
};
